import * as React from "react";
import { Container, Heading, Text, Box } from "theme-ui";
import { bookContent, BookContent } from "../components/content";
import { Helmet } from "react-helmet";

export default function Book() {
  return (
    <>
      <Helmet>
        <title>{bookContent("title")}</title>
        <meta name="description" content={bookContent("description")} />
      </Helmet>
      <Container
        variant={"small"}
        py={[3, 3, 4]}
        px={3}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexShrink: 0 }} pb={[3, 4, 4]}>
          <Heading as="h1" variant="h1">
            <BookContent at="copy.title" />
          </Heading>
          <Box
          sx={{
            bg: "#14A186",
            padding: "15px 20px",
            textDecoration: "none",
            borderRadius: "6px",
            mb: "20px"
          }}
          >
          <Text sx={{
            color: "#fff",
            textAlign: "left",
            letterSpacing: "1px",
            textDecoration: "none",
            fontSize: "24px",
          }}>
            Please note that we are re-locating to Salt Lake City and the last day to book the studio is August 21st.
          </Text>
          </Box>
          <Text>
            <BookContent at="copy.description" />
          </Text>
        </Box>
        <Box
          sx={{
            flex: "auto 1 1",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            overflow: "hidden",
          }}
          id="square-embed"
        />
      </Container>
    </>
  );
}
